<template>
    <b-row class="position-relative">
        <b-col :class="colClasses">
            <img class="img-fluid" :src="imgSrc" :alt="imgAlt">
        </b-col>
        <p class="blockquote text text-size-ls mb-0" :class="blockquoteClasses" v-html="imgBlockquote"/>
    </b-row>
</template>

<script>
    export default {
        name: "UImgBlockquote",
        props: {
            colClasses: {
                type: String,
                default: 'col-12 col-md-6',
            },
            imgSrc: {
                type: String,
                default: '/img/header-banner.png'
            },
            imgAlt: {
                type: String,
                default: 'Регистрация бизнеса онлайн'
            },
            imgBlockquote: {
                type: String,
                default: 'Наведите камеру на QR код'
            },
            blockquoteClasses: {
                type: String,
                default: 'default'
            }
        }
    }
</script>

<style scoped lang="scss">

    .blockquote {
        position: absolute;
        max-width: 50%;
        right: 0;
        @include _lg {
            max-width: 70%;
            right: 15px;
        }

        &.default {
            bottom: 0;
        }

        &.bottom {
            bottom: -30px;
            right: -55px;
            font-size: 9px;
            @include _lg {
                bottom: -35px;
                right: 15px;
            }
        }
    }
</style>